// NOTE: This file is a combination of analytics scripts and environment variables
/* eslint-env browser */
/* eslint-disable */
// @ts-nocheck

import yn from 'yn';

if (yn(import.meta.env.APP_USE_DOTCOM_REDIRECT)) {
  if (!/.com$/.test(window.location.host)) {
    window.location.href = import.meta.env.APP_URLS_APP_URL + window.location.hash;
  }
}

// vv --- Hotjar --- vv //
if (yn(import.meta.env.HOTJAR_USE_HOTJAR)) {
  // Hotjar Tracking Code for www.plooto.com
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = {
      hjid: import.meta.env.HOTJAR_ID,
      hjsv: import.meta.env.HOTJAR_SNIPPET_VERSION,
    };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, import.meta.env.HOTJAR_URL, '.js?sv=');
}
// ^^ --- Hotjar --- ^^ //

// vv --- Google Tag Manager --- vv //
const dataLayer = (window.dataLayer = window.dataLayer || []);

function loadGoogleTagManager(domain) {
  (function (w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const f = d.getElementsByTagName(s)[0];
    const j = d.createElement(s);
    const dl = l != 'dataLayer' ? `&l=${l}` : '';
    j.async = false;
    j.src = `${domain}?id=${i}${dl}`;
    f.parentNode.insertBefore(j, f);
  })(window, document, 'script', 'dataLayer', import.meta.env.GOOGLE_TAG_MANAGER_TRACKING_ID);
}
if (yn(import.meta.env.GOOGLE_TAG_MANAGER_USE_GTM)) {
  if (yn(import.meta.env.GOOGLE_TAG_MANAGER_USE_ADVANCED_ANALYTICS)) {
    window.addEventListener(
      'load',
      function (e) {
        // if unable to load, use default Google Tag Manager
        if (!window.google_tag_manager) {
          loadGoogleTagManager(import.meta.env.GOOGLE_TAG_MANAGER_FALLBACK_URL);
        }
        const script = document.createElement('script');
        script.src = import.meta.env.GOOGLE_TAG_MANAGER_PLOOTO_ADVANCED_ANALYTICS_URL;
        script.async = true;
        const head = document.head || document.getElementsByTagName('head')[0];
        head.appendChild(script);
      },
      false
    );

    loadGoogleTagManager(import.meta.env.GOOGLE_TAG_MANAGER_URL);
  } else {
    (function (w, d, s, l, i) {
      w[l] = w[l] || [];
      w[l].push({
        'gtm.start': new Date().getTime(),
        event: 'gtm.js',
      });
      const f = d.getElementsByTagName(s)[0];
      const j = d.createElement(s);
      const dl = l != 'dataLayer' ? `&l=${l}` : '';
      j.async = true;
      j.src = `${import.meta.env.GOOGLE_TAG_MANAGER_URL}?id=${i}${dl}`;
      f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', import.meta.env.GOOGLE_TAG_MANAGER_TRACKING_ID);
  }
}
// ^^ --- Google Tag Manager --- ^^ //

// vv --- Application Insights --- vv //
const appInsights =
  window.appInsights ||
  (function (a) {
    function b(a) {
      c[a] = function () {
        const b = arguments;
        c.queue.push(function () {
          c[a].apply(c, b);
        });
      };
    }
    var c = { config: a };
    const d = document;
    const e = window;
    setTimeout(function () {
      const b = d.createElement('script');
      (b.src = a.url || 'https://az416426.vo.msecnd.net/scripts/a/ai.0.js'),
        d.getElementsByTagName('script')[0].parentNode.appendChild(b);
    });
    try {
      c.cookie = d.cookie;
    } catch (a) {}
    c.queue = [];
    for (var f = ['Event', 'Exception', 'Metric', 'PageView', 'Trace', 'Dependency']; f.length; ) {
      b(`track${f.pop()}`);
    }
    if (
      (b('setAuthenticatedUserContext'),
      b('clearAuthenticatedUserContext'),
      b('startTrackEvent'),
      b('stopTrackEvent'),
      b('startTrackPage'),
      b('stopTrackPage'),
      b('flush'),
      !a.disableExceptionTracking)
    ) {
      (f = 'onerror'), b(`_${f}`);
      const g = e[f];
      e[f] = function (a, b, d, e, h) {
        const i = g && g(a, b, d, e, h);
        return !0 !== i && c[`_${f}`](a, b, d, e, h), i;
      };
    }
    return c;
  })({
    instrumentationKey: import.meta.env.APPLICATION_INSIGHTS_INSTRUMENTATION_KEY,
    disableAjaxTracking: true,
  });

window.appInsights = appInsights;
// ^^ --- Application Insights --- ^^ //


// vv --- Segment --- vv //
// eslint-disable-next-line no-unused-expressions
!(function () {
  const analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize) {
    if (analytics.invoked) {
      window.console && console.error && console.error('Segment snippet included twice.');
    } else {
      analytics.invoked = !0;
      analytics.methods = [
        'trackSubmit',
        'trackClick',
        'trackLink',
        'trackForm',
        'pageview',
        'identify',
        'reset',
        'group',
        'track',
        'ready',
        'alias',
        'debug',
        'page',
        'once',
        'off',
        'on',
        'addSourceMiddleware',
        'addIntegrationMiddleware',
        'setAnonymousId',
        'addDestinationMiddleware',
      ];
      analytics.factory = function (e) {
        return function () {
          const t = Array.prototype.slice.call(arguments);
          t.unshift(e);
          analytics.push(t);
          return analytics;
        };
      };
      for (let e = 0; e < analytics.methods.length; e++) {
        const key = analytics.methods[e];
        analytics[key] = analytics.factory(key);
      }
      analytics.load = function (key, e) {
        const t = document.createElement('script');
        t.type = 'text/javascript';
        t.async = !0;
        t.src = `https://cdn.segment.com/analytics.js/v1/${key}/analytics.min.js`;
        const n = document.getElementsByTagName('script')[0];
        n.parentNode.insertBefore(t, n);
        analytics._loadOptions = e;
      };
      analytics._writeKey = import.meta.env.SEGMENT_CDP_KEY;
      analytics.SNIPPET_VERSION = '4.15.3';
      analytics.load(import.meta.env.SEGMENT_CDP_KEY);
    }
  }
})();
// ^^ --- Segment --- ^^ //

